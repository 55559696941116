import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {
        derived_properties: {frame_type: {
          in: ["mountainbike"]
        }}
      }) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "mountainbikes"
    }}>{`Mountainbikes`}</h1>
    <p>{`Mountainbike, som betyder "bergcykel" på Svenska, används precis det låter i den Svenska översättningen, nämligen till att cykla i berg med. De har i regel bredare däck, är mer tåliga, fjädrar bak och/eller fram. Framförallt används denna typ av cykel för ren nöjescykling eller träning.`}</p>
    <h2 {...{
      "id": "sadelhöjd"
    }}>{`Sadelhöjd`}</h2>
    <p>{`Sadeln vill man gärna variera beroende på om man cyklar nerför kuperad terräng eller transporterar sig på platt mark. På paltt mark vill man ofta ha en högre sadel för att kunna använda benens fulla sturka. Men nerför, i terräng, vill man inte att sadeln ska vara i vägen då man ofta står upp eller sitter hukandes. Vissa cyklar har gått så långt att man med ett knaptryck på handtaget kan höja eller sänka sadeln.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      